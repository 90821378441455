@media print {
  .body {
    visibility: hidden;
    display: none;
  }

  .print-only {
    visibility: visible;
  }
}
