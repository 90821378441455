#root,
body,
/* html {
  background: #d6d6d6;
  font-family: sans-serif;
} */

#root {
  height: 100vh;
}
