MuiTableCell-head {
  background-color: #000000 !important;
  text-align: center;
}
/*
.MuiButton-root {
    color: rgb(151, 173, 245)!important;
    text-align: center;
}  */

div.space {
  padding-bottom: 20px;
  padding-top: 20px;
  text-align: center;
}

.centrado {
  text-align: center;
}
