body {
  margin: 0;
  padding: 20px;
  font: normal 20px/1.4 "Recursive", sans-serif;
}
h2 {
  font-size: inherit;
  margin: 0 0 12px;
}
.example {
  margin-bottom: 28px;
}

/* Responsive */
.resposive .react-colorful {
  width: auto;
}

/* Small */
.small .react-colorful {
  width: 120px;
  height: 120px;
}
.small .react-colorful__hue {
  height: 20px;
}

/* Custom poiners */
.custom-pointers .react-colorful__saturation-pointer {
  width: 16px;
  height: 16px;
  border-radius: 3px;
}
.custom-pointers .react-colorful__hue-pointer,
.custom-pointers .react-colorful__alpha-pointer {
  width: 16px;
  border-radius: 3px;
}

/* Custom layout */
.custom-layout .react-colorful {
  padding: 16px;
  border-radius: 12px;
  background: #33333a;
  box-shadow: 0 6px 12px #999;
}

.custom-layout .react-colorful__saturation {
  margin: 15px 0;
  border-radius: 5px;
  border-bottom: none;
}

.custom-layout .react-colorful__hue {
  order: -1;
}

.custom-layout .react-colorful__hue,
.custom-layout .react-colorful__alpha {
  height: 14px;
  border-radius: 5px;
}

.custom-layout .react-colorful__hue-pointer,
.custom-layout .react-colorful__alpha-pointer {
  width: 20px;
  height: 20px;
}
