.thumbnail {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  border: solid 1px #d8d6d6;
  width: 86%;
}

.thumbnail:hover {
  border: solid 3px #d8d6d6;
  width: 86%;
  cursor: pointer;
}

.focused {
  border: solid 2px #d8d6d6;
}

.page-number {
  margin-top: 4px;
}
