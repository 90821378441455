.thumbnails {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.thumbnails:hover {
  width: 88%;
}

.focuseds {
  border: solid 1px #d8d6d6;
}

.page-numbers {
  margin-top: 4px;
}
